import React from 'react'
// import '../fonts/Montserrat/Montserrat.css'
// import './index.scss'
// import '../scss/plugins/bootstrap.scss'
// import { IntlContextConsumer, changeLocale, FormattedMessage, injectIntl } from 'gatsby-plugin-intl'
// import { graphql } from 'gatsby'
// // import SEO from '../components/SEO/index'

// const languageName = {
//   pt: 'Português',
//   en: 'English'
// }
export default function Multilingual () {
  return (
    <div>a</div>
  )
}
// const Multilingual = ({ data, intl }) => {
//   console.log('data', data)
//   return (
//     <>
//       {/* <SEO pagina={ data.pagina.edges[0].node }></SEO> */}
//       <IntlContextConsumer>
//         {({ languages, language: currentLocale }) =>
//           languages.map((language, index) => {
//             return (
//               <>
//                 <a
//                   key={language}
//                   onClick={() => changeLocale(language)}
//                   style={{
//                     color: currentLocale === language ? 'yellow' : 'black',
//                     margin: 10,
//                     textDecoration: 'underline',
//                     cursor: 'pointer'
//                   }}
//                 >
//                   {languageName[language.split('-')[0]]}
//                 </a>
//               </>
//             )
//           })
//         }
//       </IntlContextConsumer>
//       <h1 className="text-center py-4">Meta Description - contentful</h1>
//       {/* <h2 className="text-center py-4">{data.pagina.edges[0].node.metaDescription}</h2> */}
//       <FormattedMessage id="welcome" />
//     </>
//   )
// }

// export const query = graphql`
//   query($locale: String) {
//     pagina:contentfulPagina(node_locale: {eq: $locale}) {
//       path
//       pageTitle
//       pageSummary
//       metaTitle
//       metaDescription
//       tags
//       node_locale
//     }
//   }
// `

// export default injectIntl(Multilingual)
